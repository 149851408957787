import React from "react";
import {getAvatarColor, getCpvCategory, getCpvIcon} from "../components/Cards/CpvAvatar";
import {Avatar, Tooltip} from "@mui/material";

export function CpvButton(props: { code: string } ) {
    const avatarColor = getAvatarColor(props.code)
    return (
        <Tooltip title={getCpvCategory(props.code)}>
        <Avatar
            sx={{
                bgcolor: avatarColor,
                width: 32,
                height: 32
            }}
        >
            { getCpvIcon(props.code) }
        </Avatar>
        </Tooltip>
    );
}
