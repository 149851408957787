import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, CardHeader, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import Typography from "@mui/material/Typography";
import {CpvCode, getLanguageString, ProductClassification} from "../../types/ProcurementNoticeList";
import {CpvAvatar} from "./CpvAvatar";

function CpvCodesCard(props: { classification: ProductClassification }) {
    const [additionalCpvs, setAdditionalCpvs] = useState<CpvCode[]>([]);

    useEffect(() => {
        if (props.classification.additionalCpvs) {
            let codes = props.classification.additionalCpvs;
            setAdditionalCpvs([...codes.sort((a, b) => a.id.code.localeCompare(b.id.code))]);
        }
    }, [props.classification]);
    let hasAdditionalCPVs = additionalCpvs && additionalCpvs.length > 0;
    let mainCPVs = props.classification.mainCpv;
    let hasMainCPVs = mainCPVs && mainCPVs.length > 0;
    return (
        <Card
            variant="outlined"
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
            {(!hasAdditionalCPVs || !hasMainCPVs) && (
                <CardHeader
                    title={"CPVs"}
                />
            )}
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: "column",
                    flex: 1,
                }}>
                {hasMainCPVs && (
                    <Box sx={{marginBottom: "16px"}}>
                        {hasAdditionalCPVs && (
                            <Typography component="h2" variant="subtitle1">
                                Main CPVs
                            </Typography>
                        )}
                        <List>
                            {mainCPVs!!.map((val) => {
                                return (
                                    <ListItem key={val.id.code} data-testid="mainCpv">
                                        <ListItemAvatar>
                                            <CpvAvatar cpvCode={val}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={val.id.code}
                                            secondary={getLanguageString(val.label)}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>
                )}
                {hasAdditionalCPVs && (
                    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                        {mainCPVs && (
                            <Typography component="h2" variant="subtitle1">
                                Additional CPVs
                            </Typography>
                        )}
                        <List
                            sx={{
                                flex: 1,
                            }}>
                            {additionalCpvs.map((val) => {
                                return (
                                    <ListItem key={val.id.code} data-testid="additionalCpvIds">
                                        <ListItemAvatar>
                                            <CpvAvatar cpvCode={val}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={val.id.code}
                                            secondary={getLanguageString(val.label)}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

export default CpvCodesCard;
