import React from "react";
import {Avatar, Tooltip} from "@mui/material";

export function FlagAvatar(props: { countryCode: string | null }) {
    function countryName() {
        if (!props.countryCode) return "?"
        if (props.countryCode === "NOR") return "Norway"
        if (props.countryCode === "SWE") return "Sweden"
        if (props.countryCode === "DAN") return "Denmark"
        return "?"
    }
    function countryFlagUrl() {
        if (!props.countryCode) return "?"
        if (props.countryCode === "NOR") return "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg"
        if (props.countryCode === "SWE") return "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg"
        if (props.countryCode === "DAN") return "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg"
        return ""
    }
    const altText = `Flag of ${countryName()}`
    return (
        <>
            <Tooltip title={countryName()}>
                <Avatar alt={altText} src={countryFlagUrl()} />
            </Tooltip>
        </>
    );
};

