import React from "react";
import {AvatarGroup, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import {CpvButton} from "./CpvButton";

// https://www.ssb.no/klass/klassifikasjoner/6
// A - Jordbruk, skogbruk og fiske
// B - Bergverksdrift og utvinning
// C - Industri
// D - Elektrisitets-, gass-, damp- og varmtvannsforsyning
// E - Vannforsyning, avløps- og renovasjonsvirksomhet
// F - Bygge- og anleggsvirksomhet
// G - Varehandel, reparasjon av motorvogner
// H - Transport og lagring
// I - Overnattings- og serveringsvirksomhet
// J - Informasjon og kommunikasjon
// K - Finansierings- og forsikringsvirksomhet
// L - Omsetning og drift av fast eiendom
// M - Faglig, vitenskapelig og teknisk tjenesteyting
// N - Forretningsmessig tjenesteyting
// O - Offentlig administrasjon og forsvar, og trygdeordninger underlagt offentlig forvaltning
// P - Undervisning
// Q - Helse- og sosialtjenester
// R - Kulturell virksomhet, underholdning og fritidsaktiviteter
// S - Annen tjenesteyting
// T - Lønnet arbeid i private husholdninger
// U - Internasjonale organisasjoner og organer

// A - Agriculture, forestry and fishing
// B - Mining and quarrying
// C - Industry
// D - Electricity, gas, steam and hot water supply
// E - Water supply, sewage and refuse collection
// F - Construction
// G - Wholesale trade, repair of motor vehicles
// H - Transport and storage
// I - Accommodation and food service activities
// J - Information and communication
// K - Finance and insurance activities
// L - Real estate activities
// M - Professional, scientific and technical activities
// N - Business activities
// O - Public administration and defence, and social security schemes under public administration
// P - Education
// Q - Health and social work activities
// R - Cultural, entertainment and recreational activities
// S - Other service activities
// T - Paid work in private households
// U - International organisations and bodies
export function CpvCategories() {
    return (
        <Grid container spacing={2}>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"01000000"}/>
                        <CpvButton code={"02000000"}/>
                        <CpvButton code={"03000000"}/>
                        <CpvButton code={"20000000"}/>
                        <CpvButton code={"77000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    A - Agriculture, forestry and fishing<br/>
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"09000000"}/>
                        <CpvButton code={"11000000"}/>
                        <CpvButton code={"76000000"}/>
                        <CpvButton code={"14000000"}/>
                        <CpvButton code={"43000000"}/>
                        <CpvButton code={"40000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    B - Mining and quarrying<br/>
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"17000000"}/>
                        <CpvButton code={"18000000"}/>
                        <CpvButton code={"19000000"}/>
                        <CpvButton code={"39000000"}/>
                        <CpvButton code={"31000000"}/>
                        <CpvButton code={"42000000"}/>
                        <CpvButton code={"24000000"}/>
                        <CpvButton code={"25000000"}/>
                        <CpvButton code={"26000000"}/>
                        <CpvButton code={"27000000"}/>
                        <CpvButton code={"28000000"}/>
                        <CpvButton code={"29000000"}/>
                        <CpvButton code={"36000000"}/>
                        <CpvButton code={"39000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    C - Industry
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Stack>
                    <Stack direction="row" spacing={2}>
                        <AvatarGroup spacing="small" max={999}>
                            <CpvButton code={"45000000"}/>
                            <CpvButton code={"50000000"}/>
                            <CpvButton code={"51000000"}/>
                            <CpvButton code={"71000000"}/>
                            <CpvButton code={"74000000"}/>
                            <CpvButton code={"44000000"}/>
                            <CpvButton code={"90000000"}/>
                            <CpvButton code={"41000000"}/>
                        </AvatarGroup>
                    </Stack>
                    <Typography>
                        D - Electricity, gas, steam and hot water supply<br/>
                        E - Water supply, sewage and refuse collection<br/>
                        F - Construction<br/>
                    </Typography>
                </Stack>
            </Grid>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"34000000"}/>
                        <CpvButton code={"60000000"}/>
                        <CpvButton code={"63000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    G - Wholesale trade, repair of motor vehicles<br/>
                    H - Transport and storage
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"55000000"}/>
                        <CpvButton code={"15000000"}/>
                        <CpvButton code={"16000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    I - Accommodation and food service activities<br/>
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"72000000"}/>
                        <CpvButton code={"48000000"}/>
                        <CpvButton code={"32000000"}/>
                        <CpvButton code={"64000000"}/>
                        <CpvButton code={"22000000"}/>
                        <CpvButton code={"78000000"}/>
                        <CpvButton code={"30000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    J - Information and communication
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"66000000"}/>
                        <CpvButton code={"67000000"}/>
                        <CpvButton code={"70000000"}/>
                        <CpvButton code={"79000000"}/>
                        <CpvButton code={"73000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    K - Finance and insurance activities<br/>
                    L - Real estate activities<br/>
                    M - Professional, scientific and technical activities<br/>
                    N - Business activities<br/>
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"65000000"}/>
                        <CpvButton code={"75000000"}/>
                        <CpvButton code={"35000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    O - Public administration and defence, and social security schemes under public administration
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"80000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    P - Education<br/>
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"33000000"}/>
                        <CpvButton code={"38000000"}/>
                        <CpvButton code={"85000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    Q - Health and social work activities
                </Typography>
            </Grid>
            <Grid xs={4}>
                <Stack direction="row" spacing={2}>
                    <AvatarGroup spacing="small" max={999}>
                        <CpvButton code={"92000000"}/>
                        <CpvButton code={"98000000"}/>
                        <CpvButton code={"37000000"}/>
                    </AvatarGroup>
                </Stack>
                <Typography>
                    R - Cultural, entertainment and recreational activities<br/>
                </Typography>
            </Grid>
        </Grid>
    );
}
