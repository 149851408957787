import React from "react";
import {ProcurementNotice} from "../types/ProcurementNoticeList";
import {Avatar, Tooltip} from "@mui/material";
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import AgricultureTwoToneIcon from "@mui/icons-material/AgricultureTwoTone";
import QuestionMarkTwoToneIcon from '@mui/icons-material/QuestionMarkTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import BrushTwoToneIcon from '@mui/icons-material/BrushTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import SportsMmaTwoToneIcon from '@mui/icons-material/SportsMmaTwoTone';
import MedicalInformationTwoToneIcon from '@mui/icons-material/MedicalInformationTwoTone';
import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import ShuffleTwoToneIcon from '@mui/icons-material/ShuffleTwoTone';
import FastRewindTwoToneIcon from '@mui/icons-material/FastRewindTwoTone';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import CleaningServicesTwoToneIcon from '@mui/icons-material/CleaningServicesTwoTone';
import AddIcCallTwoToneIcon from '@mui/icons-material/AddIcCallTwoTone';
import MilitaryTechTwoToneIcon from '@mui/icons-material/MilitaryTechTwoTone';
import WindowTwoToneIcon from '@mui/icons-material/WindowTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import WindPowerTwoToneIcon from '@mui/icons-material/WindPowerTwoTone';
import {yellow} from "@mui/material/colors";

export function FormTypeAvatar(props: { result: ProcurementNotice }) {

    function formType() {
        const formTypes = props.result.metaData.formTypes
        if (formTypes.length === 0) return "";
        return formTypes[0].replace(/_/g, " ")
    }


    function formTypeIcon() {
        const formTypes = props.result.metaData.formTypes
        if (formTypes.length === 0) return <QuestionMarkTwoToneIcon fontSize="large"/>;
        const type = formTypes[0]
        if (type === "CONTRACT_NOTICE") return <DescriptionTwoToneIcon fontSize="large"/>
        if (type === "CONTRACT_AWARD") return <EmojiEventsTwoToneIcon fontSize="large"/>
        if (type === "PREQUALIFICATION") return <AgricultureTwoToneIcon fontSize="large"/>
        if (type === "NOTICE") return <UpdateTwoToneIcon fontSize="large"/>
        if (type === "DESIGN_CONTEST") return <BrushTwoToneIcon fontSize="large"/>
        if (type === "DESIGN_CONTEST_RESULT") return <DesignServicesTwoToneIcon fontSize="large"/>
        if (type === "DYNAMIC_PURCHASING_SYSTEM") return <ShoppingCartTwoToneIcon fontSize="large"/>
        if (type === "QUALIFICATION_SYSTEM_WITH_COMPETITION") return <SportsMmaTwoToneIcon fontSize="large"/>
        if (type === "GENERAL_INFORMATION") return <MedicalInformationTwoToneIcon fontSize="large"/>
        if (type === "QUALIFICATION_SYSTEM_WITHOUT_COMPETITION") return <PostAddTwoToneIcon fontSize="large"/>
        if (type === "PERIODIC_INDICATIVE_NOTICE_WITH_COMPETITION") return <SportsMmaTwoToneIcon fontSize="large"/>
        if (type === "PERIODIC_INDICATIVE_NOTICE_WITHOUT_COMPETITION") return <ShuffleTwoToneIcon fontSize="large"/>
        if (type === "PRIOR_INFORMATION_NOTICE_WITH_COMPETITION") return <SportsMmaTwoToneIcon fontSize="large"/>
        if (type === "PRIOR_INFORMATION_NOTICE_WITHOUT_COMPETITION") return <FastRewindTwoToneIcon fontSize="large"/>
        if (type === "EUROPEAN_COMPANY") return <PublicTwoToneIcon fontSize="large"/>
        if (type === "PUBLIC_WORKS_CONCESSION") return <GroupTwoToneIcon fontSize="large"/>
        if (type === "CONCESSION_NOTICE") return <ReceiptLongTwoToneIcon fontSize="large"/>
        if (type === "PUBLIC_WORKS_CONCESSION_AWARD") return <GroupTwoToneIcon fontSize="large"/>
        if (type === "SERVICES_CONCESSION") return <CleaningServicesTwoToneIcon fontSize="large"/>
        if (type === "CALL_FOR_EXPRESSION_OF_INTEREST") return <AddIcCallTwoToneIcon fontSize="large"/>
        if (type === "CONCESSION_AWARD_NOTICE") return <MilitaryTechTwoToneIcon fontSize="large"/>
        if (type === "VOLUNTARY_EX_ANTE_TRANSPARENCY_NOTICE") return <WindowTwoToneIcon fontSize="large"/>
        if (type === "BUYER_PROFILE") return <AccountBoxTwoToneIcon fontSize="large"/>
        if (type === "MODIFICATION_OF_CONTRACT") return <HistoryEduTwoToneIcon fontSize="large"/>
        if (type === "UTILITIES_CONTRACT_NOTICE") return <WindPowerTwoToneIcon fontSize="large"/>
        if (type === "UTILITIES_CONTRACT_AWARD_NOTICE") return <WindPowerTwoToneIcon fontSize="large"/>
        if (type === "UTILITIES_PRIOR_INFORMATION_NOTICE_WITHOUT_COMPETITION") return <WindPowerTwoToneIcon fontSize="large"/>
        if (type === "UTILITIES_PRIOR_INFORMATION_NOTICE_WITH_COMPETITION") return <WindPowerTwoToneIcon fontSize="large"/>
        if (type === "UNKNOWN") return <QuestionMarkTwoToneIcon fontSize="large"/>
        return <QuestionMarkTwoToneIcon fontSize="large"/>
    }

    return (
        <Tooltip title={formType()}>
            <Avatar
                alt={formType()}
                sx={{
                    bgcolor: yellow[600]
                }}
                style={{
                    border: '3px solid yellow'
                }}
            >
                {formTypeIcon()}
            </Avatar>
        </Tooltip>
    )
}
