import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DangerousIcon from "@mui/icons-material/Dangerous";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import BugReportIcon from "@mui/icons-material/BugReport";
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import * as React from "react";
import {blue, green, orange, yellow} from "@mui/material/colors";

export function getIcon(eventType: string | undefined | null) {
    if (eventType === "New") return <AutoAwesomeIcon/>;
    if (eventType === "Deadline") return <NotificationsActiveIcon/>;
    if (eventType === "Update") return <TipsAndUpdatesOutlinedIcon/>;
    if (eventType === "Reply") return <QuestionAnswerIcon/>;
    if (eventType === "Applied") return <CloudUploadIcon/>;
    if (eventType === "Rejected") return <DangerousIcon/>;
    if (eventType === "Assigned") return <HowToRegIcon/>;
    if (eventType === "Qualified") return <VerifiedRoundedIcon/>;
    return <BugReportIcon/>
}

export function getColor(eventType: string | undefined | null) {
    if (eventType === "New") return "success.light";
    if (eventType === "Deadline") return "warning.light";
    if (eventType === "Update") return "secondary.main";
    if (eventType === "Reply") return "secondary.light";
    if (eventType === "Applied") return "success.light";
    if (eventType === "Rejected") return "error.dark";
    if (eventType === "Assigned") return "success.main";
    if (eventType === "Qualified") return "success.light";
    return "error.dark";
}

export function getLink(url: string | null): string | undefined {
    return url ? url : undefined;
}

function makeFavIconUrl(domain: string) {
    return "https://s2.googleusercontent.com/s2/favicons?domain=" + domain
}

export function getBuyerIcon(buyer: string) {
    if (!buyer) return ""
    let buyerName = buyer.toUpperCase();

    let buyers = new Map<string, string>([
        ["7N NORGE AS" , "7n.com"],
        ["A-2 NORGE AS" , "a-2.no"],
        ["ABOVEIT AS" , "aboveit.no"],
        ["ACCELIA CONSULTING AS" , "acceliaconsulting.no"],
        ["ACCENTURE AS" , "accenture.com"],
        ["ACHILLES INFORMATION AS" , "achilles.com"],
        ["ADMIRUM AS" , "admirum.no"],
        ["ADVANSIA AS" , "advansia.no"],
        ["ADVISETECH AS" , "advisetech.no"],
        ["AFRY NORWAY AS" , "afry.com"],
        ["AGENDA KAUPANG AS" , "agendakaupang.no"],
        ["AGENS AS" , "agens.no"],
        ["AGITEC AS" , "agitec.no"],
        ["AKTIESELSKAPET VINMONOPOLET" , "vinmonopolet.no"],
        ["ALTRO AS" , "altro.no"],
        ["ALV AS" , "alv.no"],
        ["ANMASO AS" , "meireles.no"],
        ["ARBEIDS- OG VELFERDSETATEN (NAV)" , "nav.no"],
        ["ARBEIDS- OG VELFERDSETATEN" , "nav.no"],
        ["ARTHUR D. LITTLE AS" , "adlittle.com"],
        ["ARVADUS AS" , "arvadus.no"],
        ["AS VINMONOPOLET" , "vinmonopolet.no"],
        ["ASPLAN VIAK AS" , "asplanviak.no"],
        ["ATEA AS" , "atea.no"],
        ["AVINOR AS" , "avinor.no"],
        ["AVO CONSULTING AS" , "avoconsulting.no"],
        ["AXAZ AS" , "axaz.com"],
        ["AZETS CONSULTING AS" , "azetsconsulting.no"],
        ["BAIN & COMPANY NORWAY AS" , "bain.com"],
        ["BANE NOR SF" , "banenor.no"],
        ["BANE NOR SF" , "banenor.no"],
        ["BDO AS" , "bdo.no"],
        ["BEARINGPOINT NORWAY AS" , ""],
        ["BEKK CONSULTING AS" , "bekk.no"],
        ["BETA MOBILITY CONSULTING AS" , ""],
        ["BINR AS" , "binr.no"],
        ["BLANK AS" , "blank.no"],
        ["BLINQ AS" , "blinq.no"],
        ["BLUE TREE AS" , "bluetree.no"],
        ["BOITANO OSLO AS" , "boitano.no"],
        ["BOUVET NORGE AS" , "bouvet.no"],
        ["BRAINBASE AS" , "brainbase.no"],
        ["BREDVID AS" , "bredvid.no"],
        ["BRIVO AS" , "brivo.no"],
        ["BSPOKE AS" , "bspoke.no"],
        ["BT CONSULTING SERVICES AS" , "bt-cs.no"],
        ["BUREAU 1618 AS" , "1618.no"],
        ["CANTERRA AS" , "canterra.no"],
        ["CAPGEMINI NORGE AS" , "capgemini.com"],
        ["CAPRA CONSULTING AS" , "capraconsulting.no"],
        ["CGI NORGE AS" , "cgi.com"],
        ["CLOUDBERRIES AS" , "cloudberries.no"],
        ["COMPUTAS AS" , "computas.com"],
        ["CRAYON AS" , "crayon.com"],
        ["CUBE IT AS" , "cube.no"],
        ["DDV" , "ddv.no"],
        ["DECA PEOPLE AS" , "decapeople.com"],
        ["DELOITTE AS" , "deloitte.com"],
        ["DEVOTEAM AS" , "devoteam.com"],
        ["DIGITALISERINGSDIREKTORATET" , "digdir.no"],
        ["DIREKTORATET FOR FORVALTNING OG ØKONOMISTYRING (DFØ)" , "dfo.no"],
        ["DIREKTORATET FOR FORVALTNING OG ØKONOMISTYRING" , "dfo.no"],
        ["ELATE AS" , "elate.no"],
        ["ENTUR AS" , "entur.no"],
        ["ENTUR AS" , "entur.no"],
        ["ERNST & YOUNG AS" , "ey.com"],
        ["EVALON AS" , "evalon.no"],
        ["EWORK GROUP NORWAY AS" , "eworkgroup.com"],
        ["EXPERIS AS" , "experis.no"],
        ["FINK AS" , "fink.no"],
        ["FLYTOGET AS" , "flytoget.no"],
        ["FOLQ AS" , "folq.no"],
        ["FORSVARSMATERIELL" , "fma.no"],
        ["FORTE DIGITAL AS" , "fortedigital.com"],
        ["GNIST CONSULTING AS" , "gnist.as"],
        ["GRITERA AS" , "gritera.com"],
        ["HABBERSTAD AS" , "habberstad.no"],
        ["HELLAND IT-PARTNER" , "hitpartner.no"],
        ["HIVE CONSULTING AS" , "hiveconsulting.no"],
        ["HOLTE CONSULTING AS" , "holteconsulting.no"],
        ["HRP AS" , "hrpas.no"],
        ["INMETA CONSULTING AS" , "crayonconsulting.no"],
        ["INSAM AS" , "insam.no"],
        ["INTEGRAL CONSULTING AS" , "integral.no"],
        ["INTEGRERINGS- OG MANGFOLDSDIREKTORATET (IMDI)" , "imdi.no"],
        ["INTERLOPE CONSULTING AS" , "interlope.no"],
        ["INVENTURA AS" , "inventura.no"],
        ["IT CONSULT AS" , "itc.no"],
        ["ITERA NORGE AS" , "itera.com"],
        ["ITERATE AS" , "iterate.no"],
        ["ITSFUN AS" , "itsfun.no"],
        ["JOWIT AS" , "jowit.no"],
        ["JPROFESSIONALS AS" , "jpro.no"],
        ["KANTEGA AS" , "kantega.no"],
        ["KNOWIT AS" , "knowit.no"],
        ["KODE OSLO AS" , "kodeoslo.no"],
        ["KODIO AS" , "kodio.no"],
        ["KOLUMBUS AS" , "kolumbus.no"],
        ["KONIK AS" , "konik.no"],
        ["KONS AS" , "kons.no"],
        ["KONSILITO AS" , "konsilito.no"],
        ["KPMG AS" , "kpmg.com"],
        ["KS (KOMMUNESEKTORENS ORGANISASJON)" , "ks.no"],
        ["KS-KOMMUNESEKTORENS ORGANISASJON" , "ks.no"],
        ["KYND AS" , "kynd.no"],
        ["KYSTVERKET" , "kystverket.no"],
        ["LERA AS" , "lera.no"],
        ["MARSTRAND AS" , "marstrand.no"],
        ["MATTILSYNET" , "mattilsynet.no"],
        ["MCKINSEY & COMPANY INC NORWAY NORSK AVDELING AV UTENLANDSK FORETAK" , "mckinsey.com"],
        ["MELIUS CONSULTING AS" , "meliusconsulting.no"],
        ["METEOROLOGISK INSTITUTT" , "met.no"],
        ["METIER AS" , "metier.no"],
        ["MILES AS" , "miles.no"],
        ["MILES OSLO AS" , "miles.no"],
        ["MOVABILITY CONSULTING AS" , "movability.io"],
        ["MULTICONSULT NORGE AS" , "multiconsult.no"],
        ["NETLIFE DESIGN AS" , "netlife.com"],
        ["NETLIGHT AS" , "netlight.com"],
        ["NOA IGNITE AS" , "noaignite.no"],
        ["NORBAY AS" , "identer.no"],
        ["NORCONSULT NORGE AS" , "norconsult.no"],
        ["NORDIC ADVISER AS" , "nordicadviser.no"],
        ["NORDRE FOLLO KOMMUNE" , "nordrefollo.kommune.no"],
        ["NORSK HELSENETT SF" , "nhn.no"],
        ["NORSK HELSENETT SF" , "nhn.no"],
        ["NORSK RIKSKRINGKASTING AS" , "nrk.no"],
        ["NUMERIKA AS" , "numerika.no"],
        ["ODIN PROSJEKT AS" , "odinprosjekt.no"],
        ["OKSE AS" , "okse.no"],
        ["OMEGAPOINT NORGE AS" , "omegapoint.no"],
        ["OSLO ECONOMICS AS" , "osloeconomics.no"],
        ["PA CONSULTING GROUP AS" , "paconsulting.com"],
        ["PIVOTIC CONSULTING AS" , "pivotic.com"],
        ["POINT TAKEN AS" , "pointtaken.no"],
        ["PRICEWATERHOUSECOOPERS AS" , "pwc.no"],
        ["PROMIS AS" , "promis-qualify.no"],
        ["QUARKS AS" , "quarks.no"],
        ["RAMBØLL NORGE AS" , "ramboll.com"],
        ["REDPILL LINPRO AS" , "redpill-linpro.com"],
        ["REGISTERENHETEN I BRØNNØYSUND" , "brreg.no"],
        ["RELATO AS" , "relato.no"],
        ["RING DIGITAL AS" , "ring.no"],
        ["RUTER AS" , "ruter.no"],
        ["SCOVILLE CONSULTING AS" , "scoville.no"],
        ["SENS CONSULTING AS" , "sensconsulting.no"],
        ["SEVJA AS" , "sevja.no"],
        ["SHORTCUT AS" , "shortcut.io"],
        ["SJØFARTSDIREKTORATET" , "sdir.no"],
        ["SJØFARTSDIREKTORATET" , "sdir.no"],
        ["SKATTEETATEN" , "skatteetaten.no"],
        ["SOLIDSQUARE AS" , "solidsquare.no"],
        ["SONAT CONSULTING AS" , "sonat.no"],
        ["SOPRA STERIA AS" , "soprasteria.no"],
        ["SPORVEIEN AS" , "sporveien.no"],
        ["STACQ AS" , "stacq.no"],
        ["STATENS KARTVERK" , "kartverket.no"],
        ["STATISTISK SENTRALBYRÅ" , "ssb.no"],
        ["STATSBYGG" , "statsbygg.no"],
        ["STAVANGER KOMMUNE" , "stavanger.kommune.no"],
        ["STORTINGET" , "www.stortinget.no"],
        ["STRATEGISK RUTEPLAN AS" , "s-r.as"],
        ["STRETCH OPTIMIZE AS" , "stretch.no"],
        ["SWECO NORGE AS" , "sweco.no"],
        ["SYKEHUSINNKJØP HF" , "sykehusinnkjop.no"],
        ["SYSINT AS" , "sysint.no"],
        ["SYSTEK AS" , "sysint.no"],
        ["SYSTRA NORGE AS" , "systra.com"],
        ["TEAKS AS" , "teakskonsulent.no"],
        ["TEAKS EIENDOM AS" , "teakseiendom.no"],
        ["TEAKS GRUPPEN AS" , "teaksgruppen.no"],
        ["TEAKS KONSULENT AS" , "teakskonsulent.no"],
        ["TEAM AGILE AS" , "teamagile.no"],
        ["TECHPROS AS" , "techpros.no"],
        ["TECHSEED AS" , "techseed.se"],
        ["TELLMANN EXECUTIVE ADVISORS AS" , "tellmann.no"],
        ["TET DIGITAL AS" , "ruter.no"],
        ["TIDYA AS" , "tidya.no"],
        ["TIETOEVRY NORWAY AS" , "tietoevry.com"],
        ["TIGER CONSULTING AS" , "tigerconsulting.as"],
        ["TILDE CONSULTING AS" , "tilde.as"],
        ["TRIFID NORGE AS" , "trifid.no"],
        ["TRIONA AS" , "triona.no"],
        ["TURTLESEC AS" , "turtlesec.no"],
        ["TWODAY AS" , "twoday.no"],
        ["TWODAY CONCEPTOS AS" , "twoday.no"],
        ["ULRIKEN CONSULTING AS" , "uc.no"],
        ["UNIBUSS AS" , "unibuss.no"],
        ["UNICONSULT AS" , "uniconsult.no"],
        ["UNIVERSITETET I BERGEN" , "uib.no"],
        ["VAAR ADVOKAT AS" , "vaar.law"],
        ["VAAR CONSULTING AS" , "vaar.law"],
        ["VALI AS" , "vali.no"],
        ["VARIANT OSLO AS" , "variant.no"],
        ["VIANOVA AS" , "vianova.no"],
        ["VYGRUPPEN AS" , "vy.no"],
        ["WEBSTEP AS" , "webstep.no"],
        ["WSP NORGE AS" , "wsp.com"],
        ["ÅLESUND KOMMUNE" , "alesund.kommune.no"],
    ]);
    
    let url = buyers.get(buyerName)
    if (url) {
        let faviconUrl = makeFavIconUrl(url);
        return faviconUrl;
    }


    if (buyerName.includes("ACHILLES INFORMATION AS")) return makeFavIconUrl("achilles.com")
    if (buyerName.includes("ACHILLES INFORMATION CENTRE")) return makeFavIconUrl("achilles.com")
    if (buyerName.includes("ACHILLES INFORMATION")) return makeFavIconUrl("achilles.com")
    if (buyerName.includes("ACHILLES SYSTEMS AS")) return makeFavIconUrl("achilles.com")
    if (buyerName.includes("ACHILLEVS INFORMATION AB")) return makeFavIconUrl("achilles.com")
    if (buyerName.includes("FORSVARET")) return makeFavIconUrl("forsvaret.no")
    if (buyerName.includes("OSLO KOMMUNE")) return makeFavIconUrl("oslo.kommune.no")

    if (buyer.toLowerCase().includes("direktorat")) return undefined
    if (buyer.toLowerCase().includes("fylke")) return undefined
    if (buyer.toLowerCase().includes("kommune")) return undefined

    console.log(`WARN favicon not found for '${buyer}'`)
    return undefined
}

export function getBuyerBackgroundColor(buyer: string) {
    if (!buyer) return green[500];
    if (buyer.toLowerCase().includes("direktorat")) return blue[500]
    if (buyer.toLowerCase().includes("fylke")) return yellow[700]
    if (buyer.toLowerCase().includes("kommune")) return orange[700]
    return green[500];
}