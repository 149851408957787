import * as React from 'react';

// Widgets
import {DataGrid, GridColDef, GridRowsProp, GridToolbar} from '@mui/x-data-grid';
import {Avatar, darken, lighten, Link, styled, Theme, Tooltip, useTheme} from "@mui/material";
// Icons
// Types
import {
    getDescription,
    getLanguageString,
    noticeID,
    ProcurementNotice,
    ProcurementNoticeList,
    WonLot
} from "../types/ProcurementNoticeList";
import useMediaQuery from "@mui/material/useMediaQuery";
import {getBuyerBackgroundColor, getBuyerIcon} from "./EventIcon";
import {grey} from "@mui/material/colors";

export const MOBILE_COLUMNS = {
    id: false,
    buyer: true,
    heading: true,
    description: false,
    locationId: false,
    estimatedValue: false,
    type: false,
    issueDate: false,
    deadline: false
};

export const ALL_COLUMNS = {
    id: false,
    buyer: true,
    heading: true,
    description: true,
    locationId: true,
    estimatedValue: true,
    type: true,
    issueDate: true,
    deadline: true
};

export default function ProcurementNoticeListList(props: { result: ProcurementNoticeList, orgNumber: string }) {

    const result: ProcurementNoticeList = props.result

    function getAmount(hit: ProcurementNotice) {
        if (!hit.amounts) return "";
        let overallContract = hit.amounts.estimatedOverallContract;
        if (overallContract) {
            return `${overallContract.value} ${overallContract.currency}`
        }
        let frameworkMaximum = hit.amounts.frameworkMaximum;
        if (frameworkMaximum) {
            return `${frameworkMaximum.value} ${frameworkMaximum.currency}`
        }
        return hit.amounts.estimatedValueText;
    }

    function getContractType(hit: ProcurementNotice) {
        return hit.metaData.formTypes.map((it) => it);
    }

    function isWinner(wonLots: WonLot[] | null) {
        if (wonLots != null) {
            const result = wonLots.find(lot =>
                lot.winner?.some(
                    winner => winner.id.nationalID && winner.id.nationalID.id === props.orgNumber
                )
            );
            return (result !== null && result !== undefined) ? "winner" : "";
        }
        return ""
    }

    const rows = result.hits.map((hit) => {
        return ({
            id: noticeID(hit),
            buyer: hit.buyers[0].id.officialName,
            heading: hit.title.list[0].text,
            description: getLanguageString(getDescription(hit)),
            locationId: hit.location.nutsContextList?.contexts?.map((it) => (it.type)),
            estimatedValue: getAmount(hit),
            type: getContractType(hit),
            issueDate: hit.doffinID?.published,
            deadline: hit.submissionDeadline,
            highlight: isWinner(hit.wonLots)
        });
    }) as GridRowsProp;

    function getDoffinLink(id: string) {
        return "https://doffin.no/notices/" + id;
    }

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'Doffin id', flex: 0.5, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.heading}>
                        <Link href={getDoffinLink(params.row.id)} underline="always" target="_blank">
                            {params.row.id}
                        </Link>
                    </Tooltip>
                )
            }
        },
        {
            field: 'buyer', headerName: 'buyer', flex: 0.2, renderCell: (params) => {
                let buyerName = params.row.buyer
                let favicon = getBuyerIcon(buyerName);
                let backgroundColor = getBuyerBackgroundColor(buyerName);

                function buyerInitial(buyer: string | undefined) {
                    if (buyer && buyer.length > 1)
                        return buyer.charAt(0).toUpperCase();
                    return "?"
                }

                return (
                    <>
                        {favicon && (
                            <Tooltip title={params.row.buyer}>
                                <Avatar
                                    alt={params.row.buyer}
                                    src={favicon}
                                    sx={{width: 26, height: 26, bgcolor: grey[50]}}
                                    style={{
                                        border: '0.1px solid gray'
                                    }}
                                />
                            </Tooltip>
                        )}
                        {!favicon && (
                            <Tooltip title={params.row.buyer}>
                                <Avatar
                                    alt={params.row.buyer}
                                    sx={{
                                        bgcolor: backgroundColor,
                                        width: 26,
                                        height: 26
                                    }}
                                    style={{
                                        border: '0.1px solid gray'
                                    }}>
                                    {buyerInitial(params.row.buyer)}
                                </Avatar>
                            </Tooltip>
                        )}
                    </>
                )
            }
        },
        {
            field: 'heading', headerName: 'Title', flex: 2, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.heading}>
                        <Link href={`/notice?noticeID=${params.row.id}&orgNumber=${props.orgNumber}`} underline="always">
                            {params.row.heading}
                        </Link>
                    </Tooltip>
                )
            }
        },
        {field: 'description', headerName: 'description', flex: 2},
        {field: 'locationId', headerName: 'locationId', flex: 0.3},
        {field: 'estimatedValue', headerName: 'estimatedValue', flex: 0.5},
        {field: 'type', headerName: 'type', flex: 0.3},
        {field: 'issueDate', headerName: 'issueDate', flex: 0.4},
        {field: 'deadline', headerName: 'deadline', flex: 0.4},
    ];

    const theme = useTheme();
    const small_screen = useMediaQuery(theme.breakpoints.up("sm"));
    const [columnVisible, setColumnVisible] = React.useState(ALL_COLUMNS);
    const [hideToolbar, setHideToolbar] = React.useState(true);

    React.useEffect(() => {
        const newColumns = small_screen ? ALL_COLUMNS : MOBILE_COLUMNS;
        setColumnVisible(newColumns);
        setHideToolbar(small_screen)
    }, [small_screen]);

    const getRowBackgroundColor = (color: string, theme: Theme, coefficient: number) => ({
        backgroundColor: darken(color, coefficient),
        ...theme.applyStyles('light', {
            backgroundColor: lighten(color, coefficient),
        }),
    });

    const StyledDataGrid = styled(DataGrid)(({theme}) => ({
        '& .app-theme--winner': {
            ...getRowBackgroundColor(theme.palette.success.main, theme, 0.7),
            '&:hover': {
                ...getRowBackgroundColor(theme.palette.success.main, theme, 0.6),
            },
            '&.Mui-selected': {
                ...getRowBackgroundColor(theme.palette.success.main, theme, 0.5),
                '&:hover': {
                    ...getRowBackgroundColor(theme.palette.success.main, theme, 0.4),
                },
            },
        },
    }));

    return (
        <StyledDataGrid
            disableColumnFilter={!hideToolbar}
            disableColumnSelector
            disableDensitySelector={!hideToolbar}
            disableColumnMenu={!hideToolbar}
            rows={rows}
            columns={columns}
            slots={{toolbar: GridToolbar}}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                },
            }}
            getRowClassName={(params) => `app-theme--${params.row.highlight}`}
            initialState={{
                sorting: {
                    sortModel: [{field: 'issueDate', sort: 'desc'}],
                },
                pagination: {paginationModel: {pageSize: 12}},
            }}
            columnVisibilityModel={columnVisible}
            pageSizeOptions={[12, 50, 100]}
        />
    );
}
