import {Box, Card, CardContent, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import { OrganizationRecord } from 'src/types/OrganizationRecordList';
import * as React from "react";

function OrganizationDescriptionCard(props: { organization: OrganizationRecord }) {
    const organization = props.organization;
    let id = organization.nationalID?.replace(organization.registerID+"-", "")
    let proffUrl = "https://proff.no/bransjes%C3%B8k?q=" + id
    return(
        <Card variant="outlined">
            <CardContent>
                {organization.street && <>
                    <Typography variant="subtitle1" component="div">
                        Address :
                    </Typography>
                    <Box>
                        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
                            {organization.street}, {organization.postcode}<br /> {organization.town}
                        </Typography>
                        {proffUrl &&
                            <Link href={proffUrl} underline="always" target="_blank">
                                {proffUrl}
                            </Link>
                        }
                    </Box>
                </>}
            </CardContent>
        </Card>
    );
}

export default OrganizationDescriptionCard;